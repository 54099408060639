<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <Breadcrumbs :title="`Add New Questions`" :items="items"/>
      <Snackbar ref="snackbar"/>

      <div class="mb-8">
        <ValidationObserver ref="observer">
          <v-form>
            <div>
              <v-expansion-panels flat v-model="panel">
                <v-expansion-panel expand v-model="panel" v-for="(items,i) in questions" :key="i" class="mb-3 rounded-lg" style="border: 1px solid #ddd">
                  <v-expansion-panel-header>
                    <span class="font-weight-bold text-h6"> Number {{ i + 1 }} </span>
                    <v-spacer></v-spacer>
                    <div class="text-right">
                      <v-btn
                        icon
                        small
                        @click.stop="confirmDelete(i)">
                        <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-expansion-panel-header>
                  <v-divider></v-divider>
                  <v-expansion-panel-content>
                    <div class="mt-7">
                      <ValidationProvider :name="items.instruction" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="items.instruction"
                          :error-messages="errors"
                          label="Instructions"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                      <ValidationProvider :name="items.question" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="items.question"
                          :error-messages="errors"
                          label="Question"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                    </div>
                    
                    <!-- ANSWER -->
                    <div v-for="(item,index) in items.options" :key="index">
                      <div class="subtitle-1 mb-1 d-flex justify-space-between">
                        <div> Option {{ index + 1 }}</div>
                        <div class="text-right">Correct Answer</div>
                      </div>
                      <v-card flat outlined class="mb-2">
                        <v-card-text>
                          <div class="d-flex">
                            <ValidationProvider :name="item.text" rules="required" v-slot="{ errors }">
                              <v-text-field
                                solo
                                flat
                                dense
                                placeholder="Tulis jawaban disini"
                                autocomplete="off"
                                color="#F05326"
                                :error-messages="errors"
                                v-model="item.text">
                              </v-text-field>
                            </ValidationProvider>
                            <v-spacer></v-spacer>
                            <v-tooltip top>
                              <template v-slot:activator="{ attrs, on }">
                                <v-btn 
                                  v-on="on"
                                  v-bind="attrs"
                                  icon
                                  class="mt-4"
                                  color="red"
                                  @click="confirmDeleteOptions(i,index)">
                                  <v-icon>
                                    mdi-trash-can-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Delete Option</span>
                            </v-tooltip>
                            <ValidationProvider :name="item.selectedValue" rules="required" v-slot="{ errors }">
                              <v-radio-group :error-messages="errors" v-model="items.selectedValue">
                                <v-radio color="#F05326" :value="item.text"></v-radio>
                              </v-radio-group>
                            </ValidationProvider>
                          </div>
                          <v-spacer></v-spacer>

                          <v-row no-gutters style="max-width: 100%;justify-content: flex-end">
                            <audio-attachment
                              :src="item.fileAudio.media_data"
                              @update="
                                $set(item.fileAudio, 'media_data', {
                                  ...item.fileAudio.media_data,
                                  ...$event,
                                })
                              "
                            />
                            <image-attachment
                              :src="item.fileImage.media_data"
                              @update="
                                $set(item.fileImage, 'media_data', {
                                  ...item.fileImage.media_data,
                                  ...$event,
                                })
                              "
                            />
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>

                    <div>
                      <v-btn 
                        @click="addOptions(i)"
                        color="#F05326"
                        text 
                        dense 
                        small 
                        elevation="0">
                        <v-icon left>mdi-plus</v-icon>
                        Add Options
                      </v-btn>
                      <v-btn 
                        @click="clearAnswer(i)"
                        color="red"
                        class="float-right"
                        text 
                        dense 
                        small 
                        elevation="0">
                        <v-icon left>mdi-close</v-icon>
                        Clear Answer
                      </v-btn>
                    </div>
                    
                    <div class="mt-5">
                      <ValidationProvider :name="items.correct_explan" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="items.correct_explan"
                          :error-messages="errors"
                          label="Correct Answer Explanation"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                      <ValidationProvider :name="items.incorrect_explan" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="items.incorrect_explan"
                          :error-messages="errors"
                          label="Incorrect Answer Explanation"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                      <ValidationProvider :name="`${items.point}`" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          v-model="items.point"
                          type="number"
                          :error-messages="errors"
                          label="Point"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                    </div>

                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div class="mt-7">
              <v-btn 
                outlined
                dense
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize"
                color="#F05326" 
                @click="addQuestion()">
                <v-icon left>mdi-plus</v-icon>
                Add Question
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize white--text float-right"
                @click="setData"
                color="#F05326">
                Save
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize black--text mr-5 float-right"
                @click="$router.push(`/questions/${$route.params.id_course}/${$route.params.id_topic}/${$route.params.id_activity}/${$route.params.activity_type}`)"
                color="#F5F5F5">
                Cancel
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>

    </div>
  </div>
</template>

<script>
import { get, post, put , destroy, upload } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import AudioAttachment from "@/components/media/layout/AudioAttachment.vue";
import ImageAttachment from '@/components/media/layout/ImageAttachment.vue'
export default {
  data() {
    return { 
      /*
       * ANSWER  single_choice_image
       */
      questions: [
        // {
        //   id: 1, 
        //   name: "Nomor 1",
        //   instruction: "",
        //   question: "",
        //   correct_explan: "",
        //   incorrect_explan: "",
        //   point: "",
        //   selectedValue:"",
        //   options:[
        //     {
        //       title: "Jawaban A",
        //       text: "",
        //       fileImage:{
        //         media_data: {}
        //       },
        //       fileAudio:{
        //         media_data: {}
        //       },
        //     }
        //   ]
        // }
      ],
      /*
       * ANSWER END 
       */
      panel: 0,
      items: [
        {
          text: 'Kosakata (Pilihan Gambar)',
          disabled: false,
          href: `/questions/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`,
        },
        {
          text: 'Add New Questions',
          disabled: true,
          href: '/activity/create',
        }
      ],
      process:{
        run: false
      }
    }   
  },
  watch: {},
  computed:{},
  components: { Breadcrumbs, Snackbar, AudioAttachment, ImageAttachment },
  mounted(){
    if(this.$route.params.id_question){
      this.fetch()
    }else {
      this.questions.push({
        id: "", 
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        selectedValue:"",
        options:[
          {
            title: "Jawaban A",
            text: "",
            fileImage:{
              media_data: {}
            },
            fileAudio:{
              media_data: {}
            },
          }
        ]
      });
    }
  },
  created(){},
  methods:{
    async fetch(){
      this.$store.state.process.run = true
      await get(`bank/v1/question/detail/${this.$route.params.id_question}`)
      .then((response) => {
        let res = response.data
        if(res.status == 200){
          this.$store.state.process.run = false
          let question = {
            id: res.data.id,
            instruction : res.data.instruction.text,
            question : res.data.question.text,
            selectedValue : res.data.correct_answer[0],
            correct_explan : res.data.explanation.true.text,
            incorrect_explan : res.data.explanation.false.text,
            point : res.data.point,
          }
          question.options = []

          res.data.options.forEach((opt,i) => {
            let fileImage = {
              media_data: {
                with_media: false,
                _id: "",
              }
            }
            let fileAudio = {
              media_data: {
                with_media: false,
                _id: "",
              }
            }
            opt.media.forEach((med,idx) => {
              if (med.type == "image") {
                fileImage = {
                  media_data: {
                    with_media: true,
                    _id: med.id,
                    media: med
                  }
                }
              }

              if (med.type == "audio") {
                fileAudio = {
                  media_data: {
                    with_media: true,
                    _id: med.id,
                    media: med
                  }
                }
              }
            })
            question.options.push({
              text: opt.text,
              fileImage,
              fileAudio
            })
          })
          // console.log(question)
          this.questions.push(question)
        }else {
          this.$store.state.process.run = false
        }
      })
    },
    /*
     * METHOD QUESTION
     */
    addQuestion() {
      this.questions.push({
        id: "", 
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        selectedValue:"",
        options:[
          {
            title: "Jawaban A",
            text: "",
            fileImage:{
              media_data: {}
            },
            fileAudio:{
              media_data: {}
            },
          }
        ]
      });
    },
    addOptions(idx){
      this.questions[idx].options.push({
        title: "Jawaban B",
        text: "",
        fileImage:{
          media_data: {}
        },
        fileAudio:{
          media_data: {}
        },
      })
    },
    confirmDelete(idx){
      this.questions.splice(idx,1)
    },
    confirmDeleteOptions(i,index){
      this.questions[i].options.splice(index,1)
    },
    clearAnswer(i){
      this.$refs.observer.reset()
      this.questions[i].selectedValue = ""
    },
    /*
    * END METHOD QUESTION 
    */
    /*
    * METHOD SAVE DATA
    */
    async setData(){
      const isValid = await this.$refs.observer.validate()
      if (this.questions.length < 1) {
        this.$store.state.process.run = false
        this.$refs.snackbar.open("error", `Soal tidak ditemukan`);
      }else{
        if (isValid) {
          this.$store.state.process.run = true
          let data = {}
          this.questions.forEach((item,i) => {
            this.$store.state.process.run = true
            data.options = []
            item.options.forEach((opt,index) => {
              data.options.push({
                media: [opt.fileImage.media_data._id,opt.fileAudio.media_data._id],
                text: opt.text
              })
            })
            data.instruction_text = item.instruction;
            data.instruction_media = "";
            data.type = "single_choice_image";
            data.question_text = item.question;
            data.question_media = "";
            data.point = parseInt(item.point);
            data.correct_answer = [item.selectedValue];
            data.status = "publish"
            data.explanation = {
              true_text: item.correct_explan,
              true_media: "",
              false_text: item.incorrect_explan,
              false_media: ""
            }

            if (item.id !== "") {
              this.save(`update`, `bank/v1/question/${this.$route.params.id_question}`, `api/v1/question/update/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.id_question}` , data)
            }else {
              this.save(`create`, `bank/v1/question/`, `api/v1/question/add/${this.$route.params.id_topic}/${this.$route.params.id_activity}`, data)
            }
          })
        }else{
          this.$store.state.process.run = false
          this.$refs.snackbar.open("error", `Form Belum Valid`);
        }
      }
      
    },
    async save(params, url_question, url_activity, data_send){
      this.$store.state.process.run = true
      if(params == `update`){
        await put(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            put(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                this.$refs.snackbar.open("#4CAF50", `New Question Updated Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Updated Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Updated Failed`);
          }
        })
      }else {
        await post(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            post(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                this.$refs.snackbar.open("#4CAF50", `New Question Added Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Added Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Added Failed`);
          }
        })
      }
    },
    /**
    * METHOD SAVE DATA END
    */
  }
}
</script>

<style>

</style>