<template>
  <attachment-layout
    type="image"
    :src="src || {}"
    :selected="file[tab] || {}"
    :disabled="disabled"
    :saving="saving"
    :tab="1"
    :show.sync="show"
    :error="errors.message"
    :readonly="isReadOnly"
    @set="setAttachment"
    @open="fetchRecents"
    @close="close">
    <template v-if="src.with_media || (src.media && src.media.type == 'image')" slot="preview">
			<v-card class="mt-7" width="450" flat>
				<div class="d-flex flex-no-wrap justify-start">
					<v-avatar
						size="400"
						tile>
						<v-img 
							style="border:1px solid #ddd" 
							class="rounded-lg" 
							contain
							:src="src.media.public_url || src.media.url"
        			:alt="src.alt">
							<template slot="placeholder">
								<v-row class="fill-height" no-gutters align="center" justify="center">
									<v-progress-circular
										indeterminate
										color="#F05326">
									</v-progress-circular>
								</v-row> 
							</template>
						</v-img>
					</v-avatar>
					<template v-if="!disabled">
						<v-btn icon small @click="fetchRecents">
							<v-icon color="teal">mdi-undo-variant</v-icon>
						</v-btn>
						<v-btn icon depressed  @click="$emit('update', { with_media: false, media: {} })">
							<v-icon color="#d31145">mdi-trash-can-outline</v-icon>
						</v-btn>
					</template>
				</div>
			</v-card>
      <!-- <v-img
        class="rounded mb-2"
        :src="src.media.public_url || src.media.url"
        :alt="src.alt">
        <template slot="placeholder">
          <v-row class="fill-height" no-gutters align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="color lighten-3">
						</v-progress-circular>
          </v-row> 
				</template>
			</v-img>
      <template v-if="!disabled">
        <v-btn
          class="mr-2"
          x-small
          icon
          outlined
          elevation="0"
          @click="fetchRecents">
          <v-icon>mdi-undo-variant</v-icon>
        </v-btn>
        <v-btn
          x-small
          icon
          outlined
          elevation="0"
          @click="$emit('update', { with_media: false, media: {} })">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template> -->
    </template>
    <template slot="tabs">
      <v-tabs color="#F05326" v-model="tab" background-color="transparent">
        <v-tab class="text-capitalize"> Riwayat </v-tab>
        <v-tab class="text-capitalize"> URL gambar </v-tab>
        <v-tab class="text-capitalize"> Dari komputer </v-tab>
      </v-tabs>
    </template>
    <template slot="tab-items">
      <v-tabs-items class="transparent fill-height" v-model="tab">
        <v-tab-item>
          <v-card-text style="height:470px;overflow:auto;" class="pb-5">
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search your audio by title . . ."
              autocomplete="off"
              v-model="searching"
              color="#F05326"
              v-on:keyup.enter="fetchRecents"
              prepend-inner-icon="mdi-magnify">
              <template v-if="searching" v-slot:append>
                <v-icon color="#F05326" @click="searching = '';fetchRecents();">mdi-close</v-icon>
              </template>
            </v-text-field>
            <v-list nav color="background" flat class="mt-3">
              <v-list-item-group
                mandatory
                active-class="bordered-1"
                v-model="selected">
                <v-row>
                  <template>
                    <v-col v-for="recent in recents" :key="recent.id" cols="4"
                      :style="recent == recents[selected] ? 'opacity:1;border: 2px solid #F05326' : 'opacity:.3'"
                      class="rounded-lg" >
											<v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
                      <v-list-item v-show="!loading" class="pa-0">
												<v-img 
													:src="recent.public_url"
													contain
													aspect-ratio="1">
													<template slot="placeholder">
														<v-row
															class="fill-height"
															no-gutters
															align="center"
															justify="center">
															<v-progress-circular
																indeterminate
																color="#F05326">
															</v-progress-circular>
														</v-row> 
													</template>
												</v-img>
                      </v-list-item>
                      <v-list-item-subtitle class="text-wrap text-center">
                        {{ recent.title }}
                      </v-list-item-subtitle>
                    </v-col>
                  </template>
                  <template v-if="recents.length < 1">
                    <v-col> No recent media </v-col>
                  </template>
                </v-row>
              </v-list-item-group>
							<div class="text-center mt-4">
                <div class="mb-2">
                  Showing {{ recents.length }} from {{ recent.total }}
                </div>
                <v-btn
                  color="primary"
                  small
                  elevation="0"
                  @click="fetchRecents">
                  More
                </v-btn>
              </div>
            </v-list>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text class="px-8 py-6">
            <v-row>
              <v-col>
                <div class="subtitle-2 mb-2">Masukkan URL</div>
                <v-text-field
                  outlined
                  dense
									color="#F05326"
                  hide-details="auto"
									autocomplete="off"
                  v-model="url"
                  @change="setFile">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="loading">
              <v-col>
                <v-skeleton-loader type="image"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row v-else-if="!_.isEmpty(file[1]) && file[1].url != undefined">
              <v-col>
                <v-img class="rounded" :src="file[1].url"></v-img>
              </v-col>
            </v-row>
            <v-row v-else-if="errors">
              <v-col>
                <div class="text-center title color--text text--lighten-3">
                  {{ errors.message }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <v-tab-item
          class="fill-height"
          style="position: relative"
          @dragover.prevent="draging = true"
          @dragleave="draging = false"
          @drop.prevent="setFile">
          <v-card-text class="fill-height d-flex">
            <v-overlay
              absolute
              :value="true"
              :z-index="draging ? 5 : -1"
              :opacity="draging ? 0.46 : 0"
            />

            <input
              hidden
              ref="file"
              type="file"
              accept="image/*"
              @change="setFile"
            />
            <v-col v-if="_.isEmpty(file[2])" class="text-center my-auto">
              <div class="mb-8">
                <v-btn
                  color="#F05326"
                  elevation="0"
									outlined
                  @click="$refs.file.click()">
                  Pilih berkas
                </v-btn>
              </div>
              <div v-if="!draging" class="subtitle-1 mb-4">
                Atau tarik file ke sini
              </div>
              <div v-else class="subtitle-1 mb-r">Lepas file di sini</div>
            </v-col>

            <v-col v-else>
              <v-img class="rounded" :src="file[2].url">
                <v-col class="text-right">
                  <v-btn
                    color="white"
                    x-small
                    fab
                    elevation="0"
                    @click="$refs.file.click()">
                    <v-icon>mdi-undo-variant</v-icon>
                  </v-btn>
                </v-col>

                <template slot="placeholder">
                  <v-row
                    class="fill-height"
                    no-gutters
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      color="#F05326">
										</v-progress-circular>
                  </v-row> 
								</template>
							</v-img>
            </v-col>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </attachment-layout>
</template>

<script>
import { get, post, put , destroy, upload, get_media } from '@/service/Axios'
import AttachmentLayout from "@/components/media/layout/AttachmentLayout";
export default {
  name: "image-attachment",
  components: {
    AttachmentLayout,
  },
  props: {
    disabled: { type: Boolean, default: false },
    src: { type: Object },
  },
  data() {
    return {
      loading: false,
      saving: false,
      show: false,
      tab: 2,
      file: [],
      selected: null,
      url: "",
      searching: "",
      recents: [],
      draging: false,
      errors: {},
      recent: {},
    };
  },
  computed: {
    isReadOnly() {
      return this.tab == 0 || this.loading || this.saving;
    },
  },
  // created() {
  //   if (this.src.media && Number(this.src.media.id)) {
  //     this.$store.dispatch("media/detail", this.src.media.id).then((res) => {
  //       let file = { with_media: true, media: res.data };
  //       this.$emit("update", file);
  //     });
  //   }
  // },
  methods: {
    async fetchRecents() {
      this.loading = true;
			this.$store.state.process.run = true
      // let page = 1 + this.recent.page || 0;
			let limit = 9 + this.recent.limit || 9;
			await get_media(`storage/v1/media`,{
        params: {
          title: this.searching,
          limit,
          // page,
          type: "image", 
          sort: 'created_at', 
          dir: 'desc' 
        },
      }).then((response) => {
				let res = response.data
				if (res.status == 200) {
					this.loading = false;
					this.$store.state.process.run = false
					this.recent = res.data;
					this.recents = res.data.list;
				}
			});
      this.show = true;
    },
    setFile($event) {
      let vm = this;
      let src = {
        url: "",
        title: "",
        alt: "",
        description: "",
        caption: "",
      };

      switch (vm.tab) {
        // From recent
        case 0:
          Object.assign(src, this.recents[$event]);
          vm.$set(vm.file, vm.tab, src);
          break;
        // From url
        case 1:
          var img = new Image();
          if (!$event) {
            vm.errors = {};
            vm.$set(vm.file, vm.tab, {});
            return;
          }

          this.loading = true;
          img.src = $event;
          img
            .decode()
            .then(() => {
              vm.errors = {};
              // src.url = img.src;
              src.url = img.src;
              src.title = img.src.split("/").pop();
              src.alt = img.alt;
              src.type = "image";
              src.source = "url";
              vm.$set(vm.file, vm.tab, src);
              this.loading = false;
            })
            .catch((error) => {
              vm.errors = error;
              vm.$set(vm.file, vm.tab, {});
              this.loading = false;
            });
          break;
        // From file
        case 2:

          if (this.draging) {
            this.draging = false;
            src.url = URL.createObjectURL($event.dataTransfer.files[0]);
            src.title = $event.dataTransfer.files[0].name;
            src.alt = $event.dataTransfer.files[0].name;
            src.file = $event.dataTransfer.files[0];
          } else {
            src.url = URL.createObjectURL($event.target.files[0]);
            src.title = $event.target.files[0].name;
            src.alt = $event.target.files[0].name;
            src.file = $event.target.files[0];
          }
          src.source = "upload";

					// console.log(src)

          vm.$set(vm.file, vm.tab, src);
          break;
        default:
          break;
      }
    },
    close() {
      this.$delete(this.file, 1);
      this.$delete(this.file, 2);
      this.show = false;
      this.recents = [];
      this.recent = {};
      this.selected = null;
      this.url = "";
    },
    setAttachment(file) {
      // FIXME: Masih bisa upload berbagai macam file
      if (this.tab) {
        this.saving = true;
				this.$store.state.process.run = true
        let formData = new FormData()
        formData.append('source',file.source)
        formData.append('alt',file.alt)
        formData.append('caption', file.caption)
        formData.append('description', file.description)
        formData.append('file', file.file)
        formData.append('title',`${file.title}`)
        formData.append('url', file.url)

        upload("storage/v1/media/upload", {
          data: formData
        }).then((response) => {
					let res = response.data
					if (res.status == 200) {
						// console.log(res);
            file = { with_media: true, _id: res.data.id, media: res.data };
            this.saving = false;
						this.$store.state.process.run = false
            this.$emit("update", file);
            this.close();
					}
				}).catch((error) => {
            this.errors.message = error.message;
            this.saving = false;
						this.$store.state.process.run = false
				});
      } else {
        file = { with_media: !this._.isEmpty(file), _id: file.id, media: file };
        this.$emit("update", file);
        this.close();
      }
    },
  },
  watch: {
    tab() {
      if (this.tab == 0) this.setFile(this.selected);
    },
    selected() {
      if (this.tab == 0) this.setFile(this.selected);
    },
  },
};
</script>

<style>
.v-application .bordered-2 {
  border: 2px solid;
  border-radius: 6px;
  border-color: var(--v-primary-base) !important;
}
</style>